<template>
  <div class="w-100 top-bar">
    <top-bar-subinstance-bar
      :fetching="fetching"
      :instance="instance"
      :key="instance.id"
      v-on="$listeners"
    />
    <template v-if="!fetching">
      <div class="px-6">
        <v-container
          class="top-bar__container px-0"
          :class="{
            'mt-8': !$vuetify.breakpoint.lgAndDown,
            'mt-6': $vuetify.breakpoint.lg,
            'mt-4': $vuetify.breakpoint.mdAndDown,
          }"
          v-if="instance.info"
        >
          <v-row
            class="justify-space-between mx-0 mb-6"
            :class="$vuetify.breakpoint.mdAndDown ? 'flex-column' : ''"
          >
            <top-bar-title
              :fetching="fetching"
              :instance="instance"
              :key="instance.id"
              v-on="$listeners"
            />

            <div
              class="top-bar-title__box d-flex justify-start justify-lg-end align-center"
              :class="$vuetify.breakpoint.smAndDown ? 'flex-wrap' : ''"
            >
              <div v-if="$vuetify.breakpoint.smAndDown" class="d-flex mt-4">
                <share-instance-modal v-if="instance.sharedAccess.length">
                  <template v-slot="{ attrs, on }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="align-self-center mr-6"
                    >
                      <shares-label-big
                        :instance="instance"
                        v-bind="attrs"
                        v-on="on"
                      />
                    </div>
                  </template>
                </share-instance-modal>

                <router-link
                  class="d-flex align-center mr-3"
                  :to="`/hosting/summary?id=${this.instance.hostingId}`"
                >
                  <span class="p-1 info--text d-flex align-center">
                    <v-icon size="16" class="mr-2" color="info"
                      >$externallink</v-icon
                    >
                    {{ $t("general.hosting") }}
                  </span>
                </router-link>
              </div>
              <div
                class="d-flex justify-start justify-lg-end align-center"
                :class="$vuetify.breakpoint.mdAndDown ? 'mt-4' : ''"
              >
                <template v-if="fetching">
                  <base-icon-button-skeleton
                    v-for="index in $vuetify.breakpoint.lgAndDown ? 2 : 5"
                    :key="index"
                  />
                </template>
                <template v-else>
                  <share-instance-modal :instance="instance">
                    <template v-slot:default="{ attrs, on }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        class="align-self-center mr-6"
                        :class="$vuetify.breakpoint.smAndDown ? 'd-none' : ''"
                        v-if="instance.sharedAccess.length"
                      >
                        <shares-label-big
                          :instance="instance"
                          icon="$shareIcon"
                          color="info"
                          :text="$t('button.share')"
                          :class="$vuetify.breakpoint.smAndDown ? 'ml-3' : ''"
                          :small="$vuetify.breakpoint.smAndDown"
                        />
                      </div>
                      <div v-bind="attrs" v-on="on">
                        <base-icon-button
                          :text="$t('button.share')"
                          icon="$shareIcon"
                          color="info"
                          class="top-bar__btn"
                          :small="$vuetify.breakpoint.smAndDown ? true : false"
                        />
                      </div>
                    </template>
                  </share-instance-modal>

                  <base-icon-button
                    :disabled="!instance.isActive"
                    :fetching="fetching"
                    @click.native="openWPLoginModal"
                    :text="$t('button.wpAdmin')"
                    icon="$wordpress"
                    color="info"
                    class="top-bar__btn"
                    :loading="checkingWpLogin"
                    :class="$vuetify.breakpoint.smAndDown ? 'ml-3' : ''"
                    :small="$vuetify.breakpoint.smAndDown ? true : false"
                    v-if="
                      instance.getUserPrivileges('wordpress.create_sso_link')
                    "
                  />

                  <template
                    v-if="
                      this.instance.subinstanceLabel !== 'staging' &&
                      instance.getUserPrivileges('general.create_staging')
                    "
                  >
                    <v-tooltip
                      transition="custom-tooltip"
                      open-delay="150"
                      bottom
                      nudge-right="4px"
                      :disabled="instance.subInstances.length < 2"
                      z-index="99"
                      v-if="!state || state == 'no_staging'"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div v-on="on" v-bind="attrs">
                          <base-icon-button
                            icon="$copy"
                            @click.native="
                              $emit('action-button-staging', instance)
                            "
                            :disabled="
                              instance.subInstances.length >= 2 ||
                              !instance.isActive
                            "
                            :class="$vuetify.breakpoint.smAndDown ? 'ml-3' : ''"
                            :text="$t('button.staging')"
                            :small="
                              $vuetify.breakpoint.smAndDown ? true : false
                            "
                            :fetching="fetching"
                            class="top-bar__btn"
                            v-if="
                              instance.getUserPrivileges(
                                'general.create_staging'
                              )
                            "
                          />
                        </div>
                      </template>

                      <span>
                        {{ $t("tooltip.topBar.staging.create.inProgress") }}
                      </span>
                    </v-tooltip>

                    <v-menu
                      transition="custom-tooltip"
                      open-delay="150"
                      bottom
                      z-index="99"
                      offset-overflow
                      v-else-if="state && state != 'no_staging'"
                      open-on-hover
                      :close-on-content-click="false"
                      nudge-left="110px"
                      min-width="272px"
                      nudge-bottom="64px"
                      origin="top center"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <div v-on="on" v-bind="attrs">
                          <template v-if="state === 'in_progress'">
                            <base-icon-button
                              :loading="true"
                              :disabled="true"
                              :text="$t('button.clone')"
                              :fetching="fetching"
                              :small="
                                $vuetify.breakpoint.lgAndDown ? true : false
                              "
                            />
                          </template>
                          <template v-else>
                            <base-icon-button
                              :icon="'$xcircle'"
                              color="error"
                              :text="$t('message.status.staging.failed')"
                              :small="
                                $vuetify.breakpoint.smAndDown ? true : false
                              "
                              :fetching="fetching"
                            />
                          </template>
                        </div>
                      </template>
                      <div class="px-4 py-4">
                        <template v-if="state === 'in_progress'">
                          <p class="p-4 text-center mb-2">
                            {{ $t("tooltip.topBar.staging.create.clone") }}
                          </p>
                          <div class="d-flex justify-center">
                            <v-btn
                              elevation="0"
                              color="error"
                              x-small
                              class="px-2"
                              max-height="24px"
                            >
                              <span class="p-5 font-weight-600">
                                {{ $t("button.cancelClone") }}
                              </span>
                            </v-btn>
                          </div>
                        </template>
                        <template v-else-if="state === 'error'">
                          <p class="p-4 text-center mb-2">
                            <i18next
                              :translation="
                                $t('tooltip.topBar.staging.create.error.plain')
                              "
                            >
                              <template #highlighted>
                                <strong>{{
                                  $t(
                                    "tooltip.topBar.staging.create.error.highlighted"
                                  )
                                }}</strong>
                              </template>
                            </i18next>
                          </p>
                          <div class="d-flex justify-center">
                            <v-btn
                              elevation="0"
                              color="primary"
                              x-small
                              class="mr-2 px-2"
                              max-height="24px"
                              @click="
                                $emit('action-button-retry-staging', instance)
                              "
                            >
                              <span class="p-5 font-weight-600">{{
                                $t("button.try")
                              }}</span>
                            </v-btn>
                            <v-btn
                              elevation="0"
                              color="error"
                              max-height="24px"
                              x-small
                              class="px-2"
                              @click="
                                $emit('action-button-cancel-staging', instance)
                              "
                            >
                              <span class="p-5 font-weight-600">{{
                                $t("button.cancel")
                              }}</span>
                            </v-btn>
                          </div>
                        </template>
                      </div>
                    </v-menu>
                  </template>

                  <base-icon-button
                    :disabled="!instance.isActive"
                    :loading="
                      loadingPushToLive || instance.hasActionInProgress()
                    "
                    @click.native="openPushToLiveModal"
                    class="primary-btn top-bar__btn"
                    :text="$t('button.pushToLive')"
                    icon="$send"
                    :fetching="fetching"
                    v-else-if="
                      this.instance.subinstanceLabel === 'staging' &&
                      instance.getUserPrivileges('general.push_to_live')
                    "
                  />

                  <base-icon-button
                    :disabled="!instance.isActive"
                    @click.native="$emit('action-button-copy', instance)"
                    class="top-bar__btn"
                    :fetching="fetching"
                    icon="$copy"
                    :text="$t('button.copy')"
                    :class="$vuetify.breakpoint.lgAndDown ? 'ml-3' : ''"
                    :small="$vuetify.breakpoint.lgAndDown ? true : false"
                    v-if="
                      !$vuetify.breakpoint.lgAndDown &&
                      instance.plan_settings.copy_instance_allowed &&
                      instance.getUserPrivileges('general.copy_instance')
                    "
                  />

                  <template :instance="instance">
                    <template
                      v-if="
                        instance.status.wordpress_available >
                        instance.status.wordpress
                      "
                    >
                      <base-icon-button
                        :disabled="!instance.isActive"
                        @click.native="openUpdateModal"
                        :fetching="fetching"
                        icon="$update"
                        :loading="
                          checkingUpdates || instance.hasUpdateInProgress()
                        "
                        class="top-bar__btn"
                        :text="$t('button.update')"
                        :small="$vuetify.breakpoint.lgAndDown"
                        v-if="instance.getUserPrivileges('wordpress.update')"
                      />
                    </template>

                    <template v-else>
                      <v-tooltip
                        transition="custom-tooltip"
                        open-delay="150"
                        bottom
                        nudge-bottom="0px"
                        v-if="instance.getUserPrivileges('wordpress.update')"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div v-on="on" v-bind="attrs">
                            <base-icon-button
                              @click.native="openUpdateModal"
                              :fetching="fetching"
                              icon="$update"
                              :disabled="true"
                              :text="$t('button.update')"
                              class="top-bar__btn"
                              :small="$vuetify.breakpoint.lgAndDown"
                            />
                          </div>
                        </template>
                        <span>{{ $t("tooltip.topBar.update") }}</span>
                      </v-tooltip>
                    </template>
                  </template>

                  <base-icon-button
                    :disabled="!instance.isActive"
                    @click.native="$emit('action-button-clearcache', instance)"
                    :fetching="fetching"
                    icon="$restore"
                    :text="$t('button.cache')"
                    :class="$vuetify.breakpoint.lgAndDown ? 'ml-3' : ''"
                    :small="$vuetify.breakpoint.lgAndDown ? true : false"
                    v-if="instance.getUserPrivileges('wordpress.clear_cache')"
                  />

                  <base-icon-button
                    v-if="instance.getUserPrivileges('general.delete_instance')"
                    :fetching="fetching"
                    icon="$xcircle"
                    :text="$t('button.delete')"
                    :color="'error'"
                    class="top-bar__btn"
                    @click.native="$emit('action-button-delete', instance)"
                  />

                  <instance-item-menu
                    v-else-if="false"
                    :inInstance="true"
                    :id="this.instance.id"
                    :instance="this.instance"
                    v-on="$listeners"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <base-icon-button
                        :on="on"
                        :fetching="fetching"
                        :attrs="attrs"
                        icon="$morehorizontal"
                        :text="
                          !$vuetify.breakpoint.lgAndDown
                            ? $t('button.more')
                            : ''
                        "
                        :small="$vuetify.breakpoint.lgAndDown"
                        :class="$vuetify.breakpoint.lgAndDown ? 'ml-3' : ''"
                      />
                    </template>
                  </instance-item-menu>
                </template>
              </div>
            </div>
          </v-row>

          <instance-top-bar-tabs
            v-if="instance.isActive"
            :instance="instance"
            :fetching="fetching"
            v-on="$listeners"
            @openModal="openedModal = true"
          />
        </v-container>
      </div>
    </template>
    <template v-else>
      <top-bar-skeleton />
    </template>
    <v-dialog
      transition="custom-dialog-transition"
      width="560px"
      v-model="openedModal"
    >
      <report-provider-tracking-code-modal
        :instance="instance"
        @modal-close="openedModal = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import TopBarTitle from "./TopBarTitle";
import InstanceTopBarTabs from "./InstanceTopBarTabs.vue";
import BaseIconButton from "../buttons/BaseIconButton.vue";
import TopBarSubinstanceBar from "./TopBarSubinstanceBar.vue";
import BaseIconButtonSkeleton from "../buttons/BaseIconButtonSkeleton.vue";
import InstanceItemMenu from "../instances/InstanceItemMenu.vue";
import SharesLabelBig from "../labels/SharesLabelBig.vue";
import ShareInstanceModal from "../modal/ShareInstanceModal.vue";

import TopBarSkeleton from "./TopBarSkeleton.vue";

import {
  OpenWPLoginModalMixin,
  OpenUpdatesModalMixin,
  OpenPushToLiveModalMixin,
} from "../../mixins/OpenModalAction";
import ReportProviderTrackingCodeModal from "../modal/ReportProviderTrackingCodeModal.vue";

export default {
  components: {
    ReportProviderTrackingCodeModal,
    TopBarTitle,
    InstanceTopBarTabs,
    BaseIconButton,
    TopBarSubinstanceBar,
    BaseIconButtonSkeleton,
    TopBarSkeleton,
    InstanceItemMenu,
    SharesLabelBig,
    ShareInstanceModal,
  },
  mixins: [
    OpenWPLoginModalMixin,
    OpenUpdatesModalMixin,
    OpenPushToLiveModalMixin,
  ],
  props: {
    instance: Object,
    fetching: Boolean,
  },
  data: function () {
    return {
      searchField: "",
      openedModal: false,
    };
  },
  computed: {
    state: function () {
      if (this.instance.subInstances.length >= 2) {
        return this.instance.subInstances[1].hasInstallStatus()
          ? this.instance.subInstances[1].hasInstallFailed()
            ? "error"
            : "in_progress"
          : false;
      } else {
        return "no_staging";
      }
    },
    wordpress: function () {
      return this.instance.status.wordpress;
    },
    woocommerce: function () {
      return this.instance.status.woocommerce;
    },
    visibleLabels: function () {
      return this.instance.labels.slice(0, 2);
    },
    hiddenLabelsNumber: function () {
      if (this.instance.labels.length > 2) {
        return this.instance.labels.length - 2;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-tab {
  text-transform: capitalize;
}

.back-button {
  .v-icon {
    color: #b0b0b0;
  }

  &:hover {
    .v-icon {
      color: map-get($primary, base) !important;
    }
  }
}

.top-bar {
  .top-bar__item {
    &:hover,
    &.v-tab--active {
      color: map-get($primary, darken1) !important;
    }
  }
}

.v-btn--vertical {
  padding-right: 12px;
}
</style>
